import React from "react";
import picture1 from "../pictures/picture4.jpg";
import picture2 from "../pictures/picture5.jpg";
import picture3 from "../pictures/picture6.jpg";
import { TbTargetArrow } from "react-icons/tb";
import { RiTreasureMapLine } from "react-icons/ri";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
const OurProcess = () => {
  return (
    <div className="ourProces">
      <div className="first-segment">
        <div className="heading">
          <h1 className="about">
            <span style={{ color: "#8e0027" }}>OUR</span>
          </h1>
          <h1 className="about us">PROCESS</h1>
        </div>
      </div>
      <div className="second-segment">
        <div className="container">
          <img src={picture2} alt="" />
          <div className="pic-text">
            <TbTargetArrow />
            <h1>Assess the current state</h1>
            <p>
              Every organization has it’s own best practices and processes for
              handling the spare parts. First thing RPM provides is assessment
              and benchmarking with worlds best practices in MRO
            </p>
          </div>
        </div>
      </div>
      <div className="third-segment">
        <div className="container">
          <img src={picture2} alt="" />
          <div className="pic-text">
            <RiTreasureMapLine />
            <h1>Proposal</h1>
            <p>
              After the assessment, we work together in order to define what
              model works best for your organization following the world class
              MRO practices.
            </p>
          </div>
        </div>
      </div>
      <div className="fourth-segment">
        <div className="container">
          <img src={picture2} alt="" />
          <div className="pic-text">
            <HiOutlinePresentationChartBar />
            <h1>Implementation and results tracking</h1>
            <p>
              Together we start with the implementation of the RPM program. We
              are establishing work processes, drum beat, reporting meetings and
              tracking the results.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
