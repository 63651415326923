import React from "react";
import picture from "../pictures/picture4.jpg";
import { RiPlaneFill } from "react-icons/ri";
import { GiBrain } from "react-icons/gi";
import { FaReact } from "react-icons/fa";

const OurServicesOffer = () => {
  return (
    <div className="servicesOffer">
      <div className="image">
        <img src={picture} />
      </div>
      <div className="first-segment">
        <div className="text-first head">
          <h1>OUR SERVICES</h1>
          <h3>What can we offer?</h3>
          <p>
            With repair spares management and manufacturing experience with the
            wide network of service partners, we can offer full support with
            managing maintenance processes.
          </p>
        </div>
        <div className="text-icons">
          <div className="text-first">
            <h1>
              <RiPlaneFill />
            </h1>
            <p>
              Available for site visits per customer need or constant on site
              presence, depending on the service package
            </p>
          </div>
          <div className="text-first">
            <h1>
              <GiBrain />
            </h1>
            <p>Know How- Team of experts with over 10 years of experience</p>
          </div>
          <div className="text-first">
            <h1>
              <FaReact />
            </h1>
            <p>
              Manufacturing team integration – We are not service providers, we
              are partners and part of you company
            </p>
          </div>
        </div>
      </div>
      <div className="second-segment">
        <ol>
          <li>
            <div className="text-box">
              <div className="number">
                <h1 className="num">1.</h1>
                <h1 className="heading">Repairs management</h1>
              </div>

              <p>
                Our engineer will lead the program and support manufacturing
                department on any parts related issues. Only action you will be
                responsible for is to report and{" "}
              </p>
            </div>
          </li>
          <li>
            <div className="text-box">
              <div className="number">
                <h1 className="num">2.</h1>
                <h1 className="heading">Obsolescence</h1>
              </div>

              <p>
                Most organizations have issues with sourcing the parts that are
                not being produced no more. Program will provide repairs of
                those parts with development of obsolescence mitigation strategy
              </p>
            </div>
          </li>
          <li>
            <div className="text-box">
              <div className="number">
                <h1 className="num">3.</h1>
                <h1 className="heading">Reporting</h1>
              </div>

              <p>
                We will be part of you team. We will be present on your daily /
                monthly direction settings, we will report all the results on
                time and work with you on KPI improvement strategy.
              </p>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default OurServicesOffer;
