import React from "react";
import picture from "../../src/pictures/picture8.jpg";
import picture1 from "../pictures/photo1.jpg";

const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="image">
        <img className="sideImage" src={picture} alt="" />
      </div>
      <div className="form">
        <img className="contactImage" src={picture1} alt="" />
        <h1 className="myDiv">Contact us</h1>
        <p>Info: info@repairpm.com</p>
        <p>Support: techsupport@repairpm.com</p>
      </div>
    </div>
  );
};

export default ContactUs;
