import Home from './components/Home';
import AboutUs from './components/AboutUs';
import OurServices from './components/OurServices';
import OurServicesOffer from './components/OurServicesOffer';
import OurProcess from './components/OurProcess';
import Reports from './components/Reports';
import OurResults  from './components/OurResults';
import ContactUs  from './components/ContactUs';

import './style.scss';
import './App.css';


function App() {
  return (
 <div>
  <Home />
  <AboutUs />
  <OurServices />
  <OurServicesOffer />
  <OurProcess />
 
  <OurResults />
  <ContactUs />
 </div>
  );
}

export default App;
