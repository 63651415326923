import React from "react";
import picture from "../pictures/picture1.jpg";
import "../style.scss";
import picture1 from "../pictures/photo1.jpg";

const Home = () => {
  return (
    <div className="home">
      <div className="image">
        <img src={picture} alt="" />
      </div>
      <div className="text">
        <img class="rpm-logo" src={picture1} alt="" />
        <p className="trademark">RPM solutions©</p>
        <h2>Repair Spares Management</h2>
        <p className="date">2023 - Serbia</p>
      </div>
    </div>
  );
};

export default Home;
