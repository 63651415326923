import React from "react";
import picture from "../pictures/picture2.jpg";
const AboutUs = () => {
  return (
    <div className="about ab">
      <div className="heading">
        <h1 className="about">
          <span style={{ color: "#8e0027" }}>ABOUT</span>
        </h1>
        <h1 className="about us">US</h1>
      </div>

      <div className="image">
        <img src={picture} alt="" />
      </div>

      <div className="text">
        <h1>"</h1>
        <p>
          We are the team of engineers that partners with the companies that are
          striving towards manufacturing excellence, supporting maintenance
          processes, with focusing on spare parts management. Our Repairable
          Parts Management (RPM) program is designed to solve the challenges
          Procurement and Reliability manufacturing leaders face. The program is
          managed by RPM through an on-site lead responsible for the end to-end
          process execution that focuses on improving part reliability and
          reducing overall part costs. We also track and report on all repairs
          you outsource – not just the ones RPM performs. KPIs include cost
          savings, reliability improvements, warranty capture, and core return
          rates.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
