import React from "react";
import picture from "../pictures/picture3.jpg";
const OurServices = () => {
  return (
    <div className="ourServices">
      <div className="container-grid">
        <div className="container">
          <div className="grid-item img">
            <img src={picture} alt="" />
          </div>
          <div className="container-box">
            <div className="grid-item box1">
              <h2>Repairs management </h2>
              <p>
                tracking, warranties and histories Competitive repair pricing
                Parts consumption reduction due to improved reliability Root
                cause focus on repetitive failures
              </p>
            </div>
            <div className="grid-item box2">
              <h2>Obsolescence</h2>
              <p>
                Obsolescence analysis Obsolescence strategy development
                Strategic sourcing of obsolete assets Obsolescence program
                deployment Supporting sustainability and continues improvement
                initiatives
              </p>
            </div>
            <div className="grid-item box3">
              <h2>Reporting</h2>
              <p>
                KPI definitions Program value Comprehensive monthly report
                Comprehensive quarterly report Comprehensive annual report
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="header">
      <h1 className="text"><span style={{ color: '#8e0027' }}>OUR</span></h1>
          <h1 className="text text-under">SERVICES</h1>
      </div>
    </div>
  );
};

export default OurServices;
